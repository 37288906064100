.google-add-class{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bg-overlay{
  background-color: rgba(17, 17, 17)!important;
}
.mycarousel .carousel-item img{
  opacity:0.5;
  height:600px!important;
}
.mycarousel .carousel-caption {
  margin-bottom:200px;
}
.mycarousel .carousel {
  height: 600px;
}

.coming-soon img{
  max-width: -webkit-fill-available;
  display:flex;
  margin-left: auto;
  margin-right: auto;
  
}

@media(max-width:700px){
  .mycarousel .carousel {
    height: 300px;
  }
  .mycarousel .carousel-item img{
     opacity:0.5; 
    height:300px!important;
  }
  .mycarousel .carousel-caption {
    margin-bottom:40px;
    font-size: 8px;
  }
  .mycarousel .carousel-caption h2{
    font-size: 16px;
  }
}
.navbar-nav .navbar-brand a{
  color: white!important;
  margin-left: 80px;
  font-size: 30px;
  font-weight: 700;
}
.navbar-nav .navbar-brand p {
  margin-top: 0;
  font-size: 12px;
  letter-spacing: 5px;
  margin-left: 53px;
  margin-bottom: -4px;
  margin-top: -16px;
}
.navbar-nav .navbar-brand img{
  width:50px;
  height:45px;
  margin-top: 5px;
}
.footer-area .single-footer-widget .navbar-brand img{
  width:50px;
  height:45px;
  margin-top: 10px;
}
.footer-area .single-footer-widget .navbar-brand p{
  margin-top: 0;
  font-size: 12px;
  letter-spacing: 5px;
  margin-left: 53px;
  margin-bottom: -4px;
  margin-top: -18px;
}
label {
  color: white;
}
.invalid-feedback {
    color: #e3ff39;
}
.above-area {
  position: relative;
  margin-top: 60px;
  background-size: cover!important;
  z-index: 1; }
  .above-area::after {
    background-color: rgba(0, 0, 0, 0.9);
    background-size: cover!important;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ""; }
    .plantsbg {
      position: relative;
      margin-top: 60px;
      background-size: cover!important;
      z-index: 1; }
      .plantsbg::after {
        background-color: rgba(0, 0, 0, 0.8);
        background-size: cover!important;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: ""; }
.navbar-brand a{
  color: white!important;
  font-size: 30px;
  font-weight: 700;
}
.plantspos {
  margin-top: 87px;
  text-align: center;
  height:100%;
}
.findplantspos{
  margin-top: 87px;
  height:100%;
  padding-bottom: 50px;
}
.feedbackpos {
  margin-top: 87px;
  padding-bottom:50px;
  height:100%;
}
.suggestionspos{
  margin-top: 87px;
  height:100%;
  padding-bottom: 50px;
}
.ourteampos{
  margin-top: 87px;
  height:100%;
  padding-top: 60px;
}
.ourteampos .cards img{
  width:200px;
  height:200px;
}
.ourteampos .cards h1{
  font-size: 26px!important;
}
.ourteampos .cards h5{
  font-size: 17px;
}
.cards{
	margin-top: 10px; 
	transition: transform .2s;
}
.cards img{
	width:220px;
	height:300px;
}
.cards h1 {
  font-size: 2.2rem!important;
}
.cards:hover{
	transform: scale(1.05);
}
@media(max-width:1300px){
  .navbar-nav .navbar-brand a{
    margin-left: 10px;
  }
}
.navbar-brand span{
  color: green!important;
}
.navbar-nav .nav-link {
  color:white!important;
  font-size: 18px;
  margin-right: 10px;
}
@media(max-width:1022px){
  .navbar-nav .nav-link {
    font-size: 15px;
  }
}
.our-services{
  margin-top:60px;
}
.our-services p{
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: justify;
    line-height: 40px;
}
.about-us p{
  font-size:18px;
  font-family: Georgia;
  text-align: justify;
  line-height: 40px;
}
.service-icon{
  margin-right: 30px;
  padding-bottom: 20px;
}
.section-img img{
  width:100%;
  height:90%;
  border-radius: 8px;
  margin-top:15px;
}
.icons img{
  margin-bottom: 15px;
}
.footer-nav ul{
  list-style: none;
}
.footer-nav .nav-link{
  padding:0px 0px !important;
}
.footer-area {
  position: relative;
  z-index: 1; }
  .footer-area::after {
    background-color: rgba(17, 49, 26, 0.9);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ""; }
  .footer-area .main-footer-area {
    position: relative;
    z-index: 1;
    padding-top: 20px; }
  .footer-area .single-footer-widget {
    position: relative;
    z-index: 1;
    margin-bottom: 10px; }
    .footer-area .single-footer-widget p {
      color: #b7b7b7;
      margin-bottom: 20px; }
    .footer-area .single-footer-widget .social-info a {
      display: inline-block;
      border: 1px solid #b7b7b7;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      color: #ffffff;
      margin-right: 5px;
      line-height: 38px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .footer-area .single-footer-widget .social-info a {
          width: 30px;
          height: 30px;
          line-height: 28px;
          font-size: 14px; } }
      .footer-area .single-footer-widget .social-info a:hover, .footer-area .single-footer-widget .social-info a:focus {
        border-color: #70c745;
        background-color: #70c745; }
    .footer-area .single-footer-widget .widget-title h5 {
      font-size: 22px;
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 20px;
    margin-top: 24px; }
    .footer-area .single-footer-widget .widget-nav ul {
      position: relative;
      z-index: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .footer-area .single-footer-widget .widget-nav ul li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%; }
        .footer-area .single-footer-widget .widget-nav ul li a {
          display: block;
          color: #b7b7b7;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 11px; }
          .footer-area .single-footer-widget .widget-nav ul li a:hover, .footer-area .single-footer-widget .widget-nav ul li a:focus {
            color: #ffffff; }
    .footer-area .single-footer-widget .single-best-seller-product {
      position: relative;
      z-index: 1;
      margin-bottom: 20px; }
      .footer-area .single-footer-widget .single-best-seller-product::after {
        margin-bottom: 0; }
      .footer-area .single-footer-widget .single-best-seller-product .product-thumbnail {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 70px;
        flex: 0 0 70px;
        max-width: 70px;
        width: 70px;
        margin-right: 30px; }
      .footer-area .single-footer-widget .single-best-seller-product .product-info a {
        display: block;
        color: #b7b7b7;
        font-size: 16px;
        font-weight: 400; }
        .footer-area .single-footer-widget .single-best-seller-product .product-info a:hover, .footer-area .single-footer-widget .single-best-seller-product .product-info a:focus {
          color: #ffffff; }
      .footer-area .single-footer-widget .single-best-seller-product .product-info p {
        margin-bottom: 0;
        color: #ffffff;
        font-weight: 500; }
    .footer-area .single-footer-widget .contact-information p {
      line-height: 1.3;
      color: #ffffff;
      margin-bottom: 13px; }
      .footer-area .single-footer-widget .contact-information p span {
        color: #b7b7b7; }
      .footer-area .single-footer-widget .contact-information p:last-child {
        margin-bottom: 0; }
  .footer-area .footer-bottom-area {
    position: relative;
    z-index: 1;}
    .footer-area .footer-bottom-area .border-line {
      width: 100%;
      height: 1px;
      background-color: #34513d; }
    .footer-area .footer-bottom-area .copywrite-text {
      position: relative;
      z-index: 1;
      padding: 5px 0; }
      @media only screen and (max-width: 767px) {
        .footer-area .footer-bottom-area .copywrite-text {
          text-align: center;
          padding-bottom: 0; } }
      .footer-area .footer-bottom-area .copywrite-text p {
        font-size: 14px;
        color: #b7b7b7;
        font-weight: 400;
        margin-bottom: 0; }
        @media only screen and (max-width: 767px) {
          .footer-area .footer-bottom-area .copywrite-text p {
            font-size: 12px; } }
        .footer-area .footer-bottom-area .copywrite-text p a {
          font-size: 14px;
          color: #ffffff;
          font-weight: 500; }
          .footer-area .footer-bottom-area .copywrite-text p a:hover, .footer-area .footer-bottom-area .copywrite-text p a:focus {
            color: #70c745; }
          @media only screen and (max-width: 767px) {
            .footer-area .footer-bottom-area .copywrite-text p a {
              font-size: 12px; } }
    .footer-area .footer-bottom-area .footer-nav {
      position: relative;
      z-index: 1;
      padding: 5px 0; }
      .footer-area .footer-bottom-area .footer-nav ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end; }
        @media only screen and (max-width: 767px) {
          .footer-area .footer-bottom-area .footer-nav ul {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center; } }
        .footer-area .footer-bottom-area .footer-nav ul li a {
          display: inline-block;
          color: #b7b7b7;
          font-size: 14px;
          font-weight: 400;
          margin: 0 15px; }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .footer-area .footer-bottom-area .footer-nav ul li a {
              margin: 0 8px; } }
          @media only screen and (max-width: 767px) {
            .footer-area .footer-bottom-area .footer-nav ul li a {
              margin: 0 5px; } 
              .footer-area .footer-bottom-area .footer-nav ul{
                margin-right: 30px;
              margin-left: -10px;}} 
          .footer-area .footer-bottom-area .footer-nav ul li a:hover, .footer-area .footer-bottom-area .footer-nav ul li a:focus {
            color: #ffffff; }
        .footer-area .footer-bottom-area .footer-nav ul li:last-child a {
          margin-right: 0; }

          .bg-24{
            background-image: url("./Images/BG/24.jpg");
          }
          .bg-23{
            background-image: url("./Images/BG/23.jpg");
          }
          .bg-35{
            background-image: url("./Images/BG/35.jpg");
          }
          .bg-3{
            background-image: url("./Images/BG/3.jpg");
          }

          .plantspos .cards img {
            width: 220px;
            height: 300px;
            border-radius: 40px;
        }
      .plantspos .cards:hover{
        cursor: pointer;
    }
    .inpgrp{
      margin-bottom: 60px;
      margin-top: 20px;
    }
    .accord {
      text-align: justify;
  }
  .accordion>.card>.card-header:hover {
    cursor: pointer;
}
.plantspos a{
  color:white!important;
}
.plantspos a:hover{
  text-decoration: none;
}
.inpgrp .fa {
  font-size: 30px!important;
}
.inpgrp .input-group-text {
  padding-top: 15px;
}
.inpgrp .form-control {
  font-size: 21px;
}
.searchpos img:hover {
    cursor: pointer ;
    transform: none;
}
.searchpos .myborder h3:hover {
  cursor: pointer ;
  transform: none;
}
.searchpos .myborder img {
  width:150px;
  height:150px;
  margin:15px;
}
.prefer .myborder img {
  width: -webkit-fill-available;
  height:180px;
  margin-bottom: -40px;
}
.searchpos .myborder .mytext {
  margin: 15px;
  width: 300px;
}
.prefer .myborder .mytext {
  margin: 15px;
  width: 300px;
}
.prefer .myborder .mytext {
  width: 300px;
  margin-top:60px;
}
.prefer .myborder .mytext p,h3{
  text-transform: capitalize;
}
.displayplantdetail .mytext h1{
  text-transform: capitalize;
}
.searchpos .myborder .mytext p,h3{
  text-transform: capitalize;
}
.searchpos .myborder p {
  text-align: left;
}
.searchpos .myborder h3 {
  text-align: left;
}

.searchpos .myborder{
  border:2px solid white;
  margin-right: 2px;
}
.searchpos .card {
  background-color: transparent!important;
  background-clip: border-box;
  border: none!important;
  border-radius: .25rem;
}
.noplantfound{
  height:400px;
  margin-bottom: 100px;
}
.noplantfound h3{
  position: relative;
top: 50%;
transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
}
.plantdetail img{
  width:100%;
  height: 400px;
  margin-top: 15px;
}
.plantdetail{
  height:100%;
  margin-top: 87px;
}
.displayplantdetail{
  margin-top: 50px!important;
  margin-bottom: 50px;
  border:1px solid white;
}
.plantsdetailbg {
  position: relative;
  margin-top: 60px;
  background-size: cover!important;
  z-index: 1; }
  .plantsdetailbg::after {
    background-color: rgba(0, 0, 0, 0.9);
    background-size: cover!important;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ""; }
    .mytext hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 3px solid rgba(243, 243, 243, 1)!important;
  }
  .plantspos .mtnegative{
    margin-top: -50px!important;
  }
  .navbar-toggler{
    background-color: white!important;
  }
  .color-fade{
    color: #abadad!important;
  }
  .pref-cat label{
    color:black!important;
  }
  /* .section img{
    width:600px;
    height:400px;
  } */
  .overlap{
    margin-left: 300px;
  }


  .section-shaped {
    position: relative;
    overflow: hidden; }
    .section-shaped.section-hero:before {
      top: 680px; }
    .section-shaped .stars-and-coded {
      margin-top: 8rem; }
    .section-shaped .shape {
      position: absolute;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%; }
      .section-shaped .shape span {
        position: absolute; }
      .section-shaped .shape + .container {
        position: relative;
        height: 100%; }
      .section-shaped .shape.shape-skew + .container {
        padding-top: 0; }
        .section-shaped .shape.shape-skew + .container .col {
          margin-top: -100px; }
      .section-shaped .shape.shape-skew + .shape-container {
        padding-top: 18rem;
        padding-bottom: 19rem; }
    .section-shaped .shape-style-1 span {
      height: 120px;
      width: 120px;
      border-radius: 50%; }
    .section-shaped .shape-style-1 :nth-child(2) {
      right: 4%;
      top: 10%;
      background: rgba(255, 255, 255, 0.1); }
    .section-shaped .shape-style-1 :nth-child(3) {
      top: 80px;
      right: 5.66666%;
      background: rgba(255, 255, 255, 0.3); }
    .section-shaped .shape-style-1 :nth-child(4) {
      top: 320px;
      right: 7%;
      background: rgba(255, 255, 255, 0.15); }
    .section-shaped .shape-style-1 :nth-child(5) {
      top: 38%;
      left: 1%;
      right: auto;
      background: rgba(255, 255, 255, 0.05); }
    .section-shaped .shape-style-1 :nth-child(6) {
      width: 200px;
      height: 200px;
      top: 44%;
      left: 10%;
      right: auto;
      background: rgba(255, 255, 255, 0.15); }

      .section-shaped .shape-style-1.shape-default.carous0{
        background: linear-gradient(150deg, #5bc3d1 85%, #50cece 70%, #58c4cc 94%); }

    .section-shaped .shape-style-1.shape-default.carous1{
      background: linear-gradient(150deg, #999b2d 85%, #8b9626 70%, #8d9e2b 94%); }

      .section-shaped .shape-style-1.shape-default.carous2{
        background: linear-gradient(150deg, #00cc66 15%, #00cc66 70%, #00cc66 94%); }

        .section-shaped .shape-style-1.shape-default.carous3{
          background: linear-gradient(150deg, #e977f8 65%, #cc67e5 70%, #ad55bf 94%); }

          .section-shaped .shape-style-1.shape-default.carous4{
            background: linear-gradient(150deg, #ff8636 65%, #ff9d5c 70%, #ff9d5c 94%); }


            .section-shaped .shape-style-1.shape-default.carous5{
              background: linear-gradient(150deg, #27b6a3 65%, #67d6e5 70%, #55afbf 94%); }

              .section-shaped .shape-style-1.shape-default.carous6{
                background: linear-gradient(150deg, #1c5b85 15%, #1f308f 70%, #3b51b1 94%); }
    

        @media (min-width: 992px) {
  .transform-perspective-right {
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg); }
  .transform-perspective-left {
    transform: scale(1) perspective(2000px) rotateY(11deg) rotateX(2deg) rotate(-2deg); } }

          .separator-bottom {
            top: auto;
            bottom: 0; }
            .separator-bottom svg {
              bottom: 0; }

              .fill-white {
                fill: #fff; }

                section {
                  display: block; 
                  overflow: hidden;}

                  .my-80{
                    margin-top: 80px;
                    margin-bottom: 20px;
                  }

                  .btn-white {
                    color: #212529;
                    background-color: #fff;
                    border-color: #fff;
                    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
                }

                .carousel-caption h3{
                  font-size: 40px;
                      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                  margin-bottom: 450px;
                }
                .mt-negative{
                  margin-bottom: -87px;
                }
                .mt-200{
                  margin-top: 87px;
                }

                .single-footer-widget .carousel-caption p{
                  font-size: 13px;
                  color: #0e140f;
                  font-family: cursive;
                  font-weight: 800;
                  margin-bottom:-70px;
                }
                .coverflow__coverflow__53z9A img{
                  height:200px;
                }
                .coverflow__coverflow__53z9A{
                  background-color: white;
                }
                .coverflow__stage__14oqC {
                  height:360px;
                  margin-bottom: -50px;
                }
                .coverflow__text__39hqd {
                  font-weight: 600;
                  color: black;
                  background: rgba(255, 255, 255, 1);
              }
                .coverflow__figure__3bk_C {
                  display: block;
                  position: relative;
                  margin: 0;
                  padding: 0;
                  flex: 0 0 auto;
                  cursor: pointer;
                  transition: transform 600ms ease;
                  backface-visibility: hidden;
                  z-index: 9;
                  align-self: center;
                  box-shadow: none;
                  -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0));;
              }
              .diseases img{
                width:400px;
                height:300px;
                margin-bottom: 20px;
                float: right;
                margin-left:15px;
              }
              @media(max-width:705px)
              {
                .diseases img{
                  width:270px;
                  height:250px;
                  margin-bottom: 20px;
                  float: right;
                }
                .common-diseases h1{
                  font-size: 26px!important;
                  margin-bottom: 35px!important;
                }
                .diseases h2,h4{
                  margin-bottom: 15px;
                  font-size: 20px;
                }
              }
              @media(max-width:555px)
              {
                .diseases img{
                  width:250px;
                  height:250px;
                  margin-bottom: 20px;
                  float: right;
                }
              }
              @media(max-width:555px)
              {
                .diseases img{
                  width:260px;
                  height:250px;
                  margin-bottom: 20px;
                  float:none;
                }
              }
              .common-diseases h1{
                margin-bottom: 60px;
              }
              .diseases p{
                text-align: justify;
              }
              .diseases h2{
                margin-bottom: 15px;
              }
              .diseases{
                margin-top: 25px;
                margin-bottom: 55px;
                padding-bottom:20px;
                border-bottom: 2px solid white;
              }
              .section-box{
                margin-top: 80px;
                margin-bottom: 20px;
              }
              @media(max-width:705px){
                .section-box {
                  margin-top: 20px!important;
                margin-bottom: 0px!important;
                }
                .section-box h1{
                  font-size:30px;
                }
                .section-box p{
                  font-size:16px;
                }
              }